
const initialState = {
  test: "start",
}
/**
 *
 * 2-2 redux
 *
 * reducer
 *
 * @param state
 * @param action
 */
function todos(state: any = [], action: any) {
  switch (action.type) {
    case "ajax_get_post":

      /**
       * state controle
       *
       */
      //console.log("ajax get return is ", action.volume);

      return Object.assign({}, state, {
        "data" : action.volume.data,
        "count" : action.volume.count
      });

      break;


    case "test":
      //console.log(action);
      //console.log("test");

      /*
      return Object.assign({}, state, {
        "test" : action.volume.test
      });
      */
      return { ...state, "test": action.volume.test }
      break;

    default:
      return state;
      break;
  }
}

export default todos;
