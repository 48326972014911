import * as React from "react";
import * as ReactDom from "react-dom";

import "./showall.scss";

import { ShowSingle } from "./showsingle";

/**
 * redux
 */
import reducer from "./showreducers";

import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import {
    select,
    call,
    put,
    takeEvery,
    takeLatest,
    delay,
    all
} from "redux-saga/effects";

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 */
function getParam(name:any) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function ceckLocal(){
    const url = window.location.href;

    let string = url;
    let regexp = RegExp('localhost', 'g')
    let result = regexp.test(string);
    console.log("result is ",result);

    if(result == true){
        return "/storage/all.json";
    }else{
        return "https://minicalender.yamato-gp.net/storage/all.json";
    }
}

function* AjaxGetPost(url: any) {
    /**
     * ajax func
     *
     * https://column.yamato-gp.net/get_json.php?limit=3
     *
     */
    const retvalue = yield call(() => {

        const callurl = ceckLocal();
        try {
            return fetch(callurl)
                .then(res => res.json())
                .then(html => {
                    return { count: html.length, data: html };
                });
        } catch (e) {
            return { count: "0" };
        }
    });
    yield put({ type: "ajax_get_post", volume: retvalue });
}

function* TestClick(v: any) {
    yield put({ type: "test", volume: v });
}

/**
 * 3-2 setting Mysaga
 */
function* MySaga() {
    yield all([
        takeLatest("Ajax_Get_Post", AjaxGetPost),
        takeLatest("Test_Click", TestClick)
    ]);
}


/**
 * state
 */
interface BaseState {}

/**
 *
 *
 *
 */
class BaseAppShow extends React.Component<any, BaseState> {
    state: BaseState = {};

    public componentDidMount() {
        this.props.dispatch({ type: "Ajax_Get_Post", url: "test2" })

    }
    public componentWillReceiveProps() {}
    public componentDidUpdate() {}

    /**
     * component render
     */
    public render() {

        //console.log("props is ",this.props.todos);


        // パラメータから取得する形式の場合
        // これは一旦廃止
        // const requestparam = getParam('area');
        const kdom = document.getElementById("laravel_app_03");
        const requestparam = kdom.getAttribute('data-area');

        console.log("request area is ",requestparam);

        const showComponent = this.props.todos.data != null ?
        this.props.todos.data.map((v:any,i:any)=>{

            const jsond = JSON.parse(v.draft_data || "null");


                let hantei=[];

                if(requestparam==null){

                    hantei = [{ok:"ok"}];
                }else if(requestparam=="all"){
                    hantei = [{ok:"ok"}];
                }else{
                    hantei = jsond.relation_tempo.filter((vv:any,ii:any)=>{
                        //console.log(vv.roma);
                        if(vv.roma == requestparam && vv.checked == true){
                            return 1;
                        }else if(vv.roma == "all" && vv.checked == true){
                            return 1;
                        }
                    });
                    //console.log("hantei is ",hantei);

                }




            if(hantei.length > 0){
                return(
                    <span className="minicalender20200405flexbox" key={i}>
                    <ShowSingle sourcedata={jsond} pincolor={jsond.pincolor} />
                    </span>
                )
            }else{
                return "";
            }

        }) : (
            <p>
                now loading・・・
            </p>
        );

        /**
         *
         */
        return (
            <div className="minicalender20200405">
                {showComponent}
            </div>
        );
    }
}

/**
 * 1-3 set App
 */
const App: any = (() => {
    const mapStatetoProps = (state: any) => {
        return { ...state };
    };
    return connect(mapStatetoProps)(BaseAppShow);
})();

/**
 * 3-4 sagaMiddleware
 */
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(MySaga);

/**
 * 1-4 set ReactDom.render
 */

ReactDom.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("laravel_app_03")
);

/**
 *
 */
//ReactDOM.render(<BaseAppShow />, document.getElementById("laravel_app_03"));
